import React from 'react';
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        {/* <h1>Pagina no encontrada</h1>
        <p>Dirigete a <a href="https://www.xubit.com.ar" target="_self"> www.xubit.com.ar </a></p> */}
      </Layout>
    );
  }
}

export default NotFoundPage;
